<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/Layout.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
    components: {
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack
    },
    layout: Layout
};
</script>

<template>
    <Splide class="w-full" :has-track="false" aria-label=""
            :options="{ rewind: true, perPage: 4, pagination: false, breakpoints: {640: {perPage: 1},1100: {perPage: 2}} }">
        <SplideTrack>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple iPad Mini 6.Nesil 8.3" Wi-Fi 64GB Tablet</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Tablet ve Aksesuar</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">02.05.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            3 aylığına iPad ihtiyacım vardı ama tam olarak hangi modele ihtiyacım olduğuna emin değildim. Kiralabunu aklıma geldi ama iPad olabileceğine ihmal vermemiştim. Yanılmışım. Bir çok model hatta Apple Pencil bile buldum. Süreç hızlıydı, çok memnun kaldım. Cihaz tertemiz geldi, bir daha herhangi bir ürüne ihtiyacım olduğunda ilk adresim kiralabunu olacak.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">
                                GÇ
                            </div>
                            <div class="ml-2 text-base font-bold">Günce Ç.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Xiaomi Mi Air Purifier 4 Lite Akıllı Hava Temizleyici</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Elektrikli Ev Aletleri</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">09.04.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Kedi alerjisi için almıştım kesin çözüm olmasa da etkili olduğunu söyleyebilirim. Amacım zatrn ürünün performansını deneyimlemekti. Kiralabunu aracılığı ile 2 aylık deneme sonucunda almaya karar verdim ve artık benim oldu! Bu hizmet için teşekkür ederim KiralaBunu!
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">HZ</div>
                            <div class="ml-2 text-base font-bold">Hümeyra Z.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Sony PS5 PULSE 3D Wireless Headset</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Ses & Müzik</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">10.05.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Beklentimin üstünde bir ürün ve hizmetle karşılandım. Kiralamak deyince genel olarak en az 3. ncü elden başlayarak 4-5-6. ıncı el gibi kullanılıp deforme olmuş ürünlerle karşılaştım. Ürününüz tarafıma son model ve sıfır olarak geldi. Dönemsel ihtiyaçımı karşıladığı gibi kullanmaktan da zevk aldığımı belirtmek isterim. Teşekkürler
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">CÇ</div>
                            <div class="ml-2 text-base font-bold">Celal Ç.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Wero Akıllı Kedi/Köpek Su Şelalesi</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Ev & Eğlence</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">13.06.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Kızım ürünü çok sevdi. Bu sıcak havalarda hem temiz suyunu içiyor hem de yüzünü yıkayıp ferahlıyor. Ciddi şekilde onun oyun arkadaşı oldu. Teşekkürler Kiralabunu
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">CÇ</div>
                            <div class="ml-2 text-base font-bold">Cemile Ç.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Xiaomi Mi 360º Home Security Kamera 2K Pro</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Kameralar</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">29.04.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Satın almak yerine daha uygun fiyatla kiraladım, çok memnun kaldım.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">MN</div>
                            <div class="ml-2 text-base font-bold">Merve N.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Sony Playstation 4 Slim 500GB</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Oyun Konsolu & VR</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">09.01.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Neredeyse sıfıra yakın ürün geldi. 4 aydır sorunsuz kullanmaktayım. Tavsiye ederim. Güzel bir sistem. 6 ay kiraladım 6 ay dolunca ps5 kiralamayı düşünüyorum. İlgi alaka ve hızlı çözümleriniz için teşekkürler.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">MD</div>
                            <div class="ml-2 text-base font-bold">Mert D.</div>
                        </div>
                    </div>
                </div>
            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple Airpods Max</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Ses & Müzik</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">17.02.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Her şey yazıldığı gibi gerçekleşti. Gayet memnun edici!
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">AN</div>
                            <div class="ml-2 text-base font-bold">Anonymous</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple iPhone 13 128GB</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Telefon ve Aksesuar</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">09.04.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Geçici telefon ihtiyacını kiralama ile karşılama imkanı çok iyi. Ürün sıfır kutusunda verildi. İşlemler sorunsuz 20 dakikada tamamlandı.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">KB</div>
                            <div class="ml-2 text-base font-bold">Kerim B.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Samsung Galaxy Tab S8 Ultra 256GB</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Tablet ve Aksesuar</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">24.06.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Kiralabunu hizmetinden memnun kaldım. Tablet kullanımı pc'ye alışmış bir nesil için gerçekten zor, alışamadım. Bundan dolayı ürünü kullandıktan sonra teslim ettim . Bu deneyimi sunduğunuz için teşekkürler kiralabunu.com
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">VA</div>
                            <div class="ml-2 text-base font-bold">Vural A.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Electrolux EKF7700 Zaman Ayarlı Kahve Makinesi</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Kahve Makineleri</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">16.07.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Günlük kullanım için gayet yeterli ve başarılı. Kısa sürede, sıfır ürün geldi. Teşekkürler Kiralabunu...
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">MH</div>
                            <div class="ml-2 text-base font-bold">Mehmet H.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Sony Playstation 5</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Oyun Konsolu & VR</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">01.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün elime geldiğinde bir sıkıntı yaşamadım Kiralabunu.com u kesinlikle tavsiye ediyorum ürünün bana göndermeden önce sıfırmış gibicesine temiz bir şekilde elime ulaştı
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">NK</div>
                            <div class="ml-2 text-base font-bold">Necati K.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex pb-4">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple iPhone 13 128GB</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Telefon</div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">09.03.2024</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Sıfır kutusunda teslim edildi. Hızlı bir teslimattı.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 text-center">A</div>
                            <div class="ml-2 text-base font-bold">Anonymous</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
        </SplideTrack>

        <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev !-left-9">
                <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737"
                     viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
            <button class="splide__arrow splide__arrow--next !-right-9">
                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926"
                     height="20.737" viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
        </div>
    </Splide>
</template>
